<template>
  <v-app-bar
    color="nav"
    clipped-left
    app
  >
    <div class="d-flex align-center">
      <v-btn
        icon
        color="white"
        :small="$vuetify.breakpoint.smAndDown"
        @click="onBack"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-toolbar-title
        class="text--white"
        style="font-size: 18px"
        :class="$vuetify.breakpoint.smAndDown && 'text-body-1 pl-2'"
      >
        <div v-if="$vuetify.breakpoint.smAndUp">
          <span>{{ title }} {{ documentNumber }}</span>
        </div>
        <div v-else>
          <span>{{ title }}</span>
          <span class="d-block">{{ documentNumber }}</span>
        </div>
      </v-toolbar-title>
    </div>
    <v-spacer />
    <app-top-bar-user />
  </v-app-bar>
</template>

<script>
import AppTopBarUser from '@/components/shared/User';

import { mapState, mapMutations } from 'vuex';
import { SET_DOCUMENT_NUMBER } from '@/store/mutation-types';

export default {
  name: '',
  components: {
    AppTopBarUser,
  },
  data: () => ({}),
  computed: {
    ...mapState('simpleTop', ['documentNumber']),
    title() {
      const { title } = this.$route.meta;
      return title;
    },
  },
  methods: {
    ...mapMutations('simpleTop', [SET_DOCUMENT_NUMBER]),
    onBack() {
      this[SET_DOCUMENT_NUMBER]('');
      const { parentRouteName: name } = this.$route.meta;
      return name ? this.$router.push({ name }) : this.$router.back();
    },
  },
};
</script>
